import { useState } from 'react';
import {
  Dialog as MuiDialog,
  DialogTitle as MuiDialogTitle,
  DialogContent as MuiDialogContent,
  DialogActions as MuiDialogActions,
  IconButton,
  Typography,
  Button,
  Select,
  MenuItem,
  InputLabel,
  Input,
  Checkbox
} from "@material-ui/core";
import { Close as CloseIcon } from "@material-ui/icons";
import { withStyles } from "@material-ui/core/styles";
import { useStyles } from "../hooks";
import styled from "styled-components";


interface DialogProps {
  open: boolean;
  onClose?: () => void;
  onSave?: (parameters: {
    filename: string,
    file: string,
    cloudCover: number,
    onlyCreate?: boolean
  }) => void;
  title: string;
}

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const Spacer = styled.div``;
export const Dialog = ({ open, onClose, title, onSave }: DialogProps) => {
  const classes = useStyles();
  const [cloudCover, setCloudCover] = useState(5);
  const [shapeFileContents, setShapeFileContents] = useState<any>(null);
  const [shapeFilePath, setShapeFilePath] = useState('');
  const [shapeFileName, setShapeFileName] = useState('');
  const [onlyCreate, setOnlyCreate] = useState(false);
  const fileReader = new FileReader();
  const handleFileChange = (e:any) => {
    console.log({target:e.target})
    fileReader.onloadend = (e) => {
      setShapeFileContents(fileReader.result);
    };
    fileReader.readAsText(e.target.files[0]);
    setShapeFilePath(e.target.value);
    setShapeFileName(e.target.files[0].name);
  };
  const handleAdd = (e:any) => {
    if(!!onSave) onSave({filename: shapeFileName, file: shapeFileContents, cloudCover, onlyCreate});
  };
  return (
    <MuiDialog open={open}>
      <MuiDialogTitle disableTypography className={classes.root}>
        <Typography variant="h6">{title}</Typography>
        {onClose ? (
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
      <DialogContent dividers>
        <InputLabel id="cloud-cover-label">Cloud Cover</InputLabel>
        <Select
          labelId="cloud-cover-label"
          id="cloud-cover-select"
          value={cloudCover}
          onChange={(e:any) => {setCloudCover(e.target.value)}}
        >
          <MenuItem value={5}>5</MenuItem>
          <MenuItem value={10}>10</MenuItem>
          <MenuItem value={20}>20</MenuItem>
          <MenuItem value={30}>30</MenuItem>
        </Select>
        <Spacer/>
        <InputLabel id="geometry-label">Geometry file</InputLabel>
        <Input
          placeholder="Polygon Shape File"
          id="geometry-input"
          value={shapeFilePath}
          type="file"
          onChange={handleFileChange}
        />
        <InputLabel id="toggle-label">Only Create Event</InputLabel>
        <Checkbox
          id="toggle-input"
          onChange={()=>{setOnlyCreate(!onlyCreate)}}
          checked={onlyCreate}
        />
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleAdd} color="primary">
          Add
        </Button>
      </DialogActions>
    </MuiDialog>
  );
};
