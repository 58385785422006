import { useState, useEffect } from 'react';
import './App.css';
import styled from 'styled-components/';
import { useAuth0 } from "@auth0/auth0-react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Button,
} from '@material-ui/core';
import {
  Menu as MenuIcon,
  Refresh as RefreshIcon,
  Add as AddIcon,
  AccountCircle
} from '@material-ui/icons';
import { useUsers, useEvents, useStyles } from './hooks';
import { makeHeader, URL } from './utils';
import { Dialog, CollapsibleTable } from './components';
import axios from 'axios';
import clsx from 'clsx';


const Container = styled.div``;
const DrawerContainer = styled.div``;
const Spacer = styled.div``;
const MainContent = styled.main``;
const colors = {
  "Status": {
    "FINISHED": "green",
    "ERROR": "red",
    "STARTED": "cyan",
    "REQUESTED": "yellow",
    "CREATED": "blue"
  }
}

function App() {
  const { isLoading, error, isAuthenticated, loginWithRedirect, getAccessTokenSilently, logout } = useAuth0();
  const [token, setToken] = useState<string|undefined>(undefined);
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState<string|undefined>(undefined);
  const [openDialog, setOpenDialog] = useState(false);
  const classes = useStyles();
  const [users, loadingUsers, loadedUsers, refreshUsers] = useUsers();
  const [events, loadingEvents, loadedEvents, getEvents, updateLocal] = useEvents();

  useEffect(() => {
    if(isAuthenticated && !token){
      getAccessTokenSilently({
        scope:
          "openid profile email",
      }).then(setToken);
    }
  }, [isLoading, isAuthenticated, getAccessTokenSilently, token]);


  useEffect(() => {
    if(isAuthenticated) getEvents(search);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, isAuthenticated])

  if (isLoading) {
    return <div>Loading...</div>;
  }
  if (error) {
    return <div>Oops... {error.message}</div>;
  }
  if (!isLoading && !isAuthenticated) {
    loginWithRedirect();
  };
  if(!token){
    return <div>Loading...</div>;
  }
  const handleDrawerToggle = () => setOpen(!open);
  const onClickStart = async (eventId:string) => {
    const requestEventResponse = await axios.post(`${URL}/api/${eventId}`, undefined, makeHeader(token));
    const requestEvent = await requestEventResponse.data;
    updateLocal(requestEvent);
  };
  const onClickGetReport = async (eventId:string) => {
    const reportURLResponse = await axios.get(`${URL}/api/${eventId}/report`, makeHeader(token));
    const reportURL = await reportURLResponse.data;
    window.open(reportURL.url, "_blank");
  };
  const onSave = async ({filename, file, cloudCover, onlyCreate}: {
    filename: string,
    file: string,
    cloudCover: number,
    onlyCreate?: boolean
  }) => {
    const createResponse = await axios.post(
      "/api",
      {
        cc: cloudCover,
        filename
      },
      makeHeader(token)
    );
    const createEvent = await createResponse.data;
    updateLocal(createEvent)
    const uploadFileRequestResponse = await axios.post(`${URL}/api/${createEvent.id}/upload`, undefined, makeHeader(token));
    const uploadURL = (await uploadFileRequestResponse.data).url;
    await axios.put(uploadURL, JSON.parse(file));
    if(!onlyCreate){
      const requestEventResponse = await axios.post(`${URL}/${createEvent.id}`, undefined, makeHeader(token));
      const requestEvent = await requestEventResponse.data;
      updateLocal(requestEvent);
    }
    setOpenDialog(false);
  };
  return (
    <Container className={classes.root}>
      <Dialog open={openDialog} title="Create Estimation" onSave={onSave} onClose={() => {setOpenDialog(false)}}/>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <IconButton onClick={handleDrawerToggle} edge="start" color="inherit" aria-label="menu">
            <MenuIcon className={clsx(classes.menuIcon, !!open ? classes.drawerOpen : classes.drawerClosed)}/>
          </IconButton>
          <Typography className={classes.title} noWrap variant="h6" >
            Estimations
          </Typography>
          <Spacer className={classes.grow} />
          <IconButton className={classes.addButton} onClick={() => {setOpenDialog(true)}} edge="end" color="inherit" aria-label="Add Estimation">
            <AddIcon />
            <Typography className={classes.title} noWrap variant="button" >
              Add
            </Typography>
          </IconButton>
          <IconButton
            edge="end"
            aria-label="Refresh"
            onClick={() => {
              getEvents(search);
              refreshUsers();
            }}
            color="inherit"
          >
            <RefreshIcon/>
          </IconButton>
          <IconButton
            edge="end"
            aria-label="Logout"
            onClick={() => logout()}
            color="inherit"
          >
            <Typography>
              Logout
            </Typography>
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="left"
          open={open}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <Toolbar />
          <Divider />
          <DrawerContainer className={classes.drawerContainer}>
            <List>
              <ListItem onClick={() => setSearch("all")} button key={"all"}>
                <ListItemText primary="All" />
              </ListItem>
              {users.map((user) => (
                <ListItem onClick={() => setSearch(user.email)} button key={user.email}>
                  <ListItemIcon><AccountCircle /></ListItemIcon>
                  <ListItemText primary={user.email} />
                </ListItem>
              ))}
            </List>
          </DrawerContainer>
        </Drawer>
        <MainContent className={classes.mainContent}>
          <Toolbar />
          <Container className={classes.row} />
          <CollapsibleTable
            colors={colors}
            headers={["id", "Cloud Cover", "File Name", "Status", "__Action"]}
            rows={events.map((event) => ({
              values: {
                id: event.id,
                "Cloud Cover": event.parameters.cc,
                "File Name": event.parameters.filename,
                "Status": event.status,
                "__Action": (event.status === "CREATED" && (<Button onClick={() => onClickStart(event.id)} color="primary">Start</Button>)) || (event.status === "FINISHED" && (<Button onClick={() => onClickGetReport(event.id)} color="primary">Get Report</Button>)),
              },
              sub:{
                headers: ["Requested Time", "Start Time", "Finished Time"],
                values: {
                  "Requested Time": event.time_requested, 
                  "Start Time": event.time_started,
                  "Finished Time": event.time_finished
                }
              }
            }))}
          />
        </MainContent>
    </Container>
  );
}

export default App;
