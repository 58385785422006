import { useState, useEffect, useCallback } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { makeHeader, URL } from "../utils";
import { alpha, makeStyles } from '@material-ui/core/styles';

export interface User {
  email: string;
  owned_event_ids: string[];
}

export interface Event {
  id: string; 
  parameters: {[key:string]: any};
  result:  {[key:string]: any};
  status: "CREATED" | "REQUESTED" | "STARTED" | "FINISHED" | "ERROR";
  time_requested: Date 
  time_started: Date 
  time_finished: Date 
}

export function useUsers(): [User[], boolean, boolean, () => void] {
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [users, setUsers] = useState<User[]>([]);
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [firstLoad, setFirstLoad] = useState(false);

  const load = useCallback(async () => {
    setLoading(true);
    setLoaded(false);
    const token = await getAccessTokenSilently();
    const response = await axios.get(`${URL}/api/users`, makeHeader(token));
    const users = (await response.data) as User[];
    setUsers(users);
    setLoading(false);
    setLoaded(true);
  }, [getAccessTokenSilently]);

  useEffect(() => {
    if(isAuthenticated && !firstLoad){
      setFirstLoad(true);
      load();
    }
  }, [isAuthenticated, firstLoad, load])

  return [users, loading, loaded, load]
}

export function useEvents(): [Event[], boolean, boolean, (email: string|undefined) => void, (event:Event) => void] {
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [events, setEvents] = useState<Event[]>([]);
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [firstLoad, setFirstLoad] = useState(false);

  const load = useCallback(async (email: string|undefined = undefined) => {
    setLoading(true);
    setLoaded(false);
    const token = await getAccessTokenSilently();
    const config = email !== undefined ? {
      ...makeHeader(token),
      params: {
        email
      }
    } : makeHeader(token);
    const response = await axios.get(`${URL}/api`, config);
    const events = (await response.data) as Event[];
    setEvents(events);
    setLoading(false);
    setLoaded(true);
  }, [getAccessTokenSilently]);

  useEffect(() => {
    if(isAuthenticated && !firstLoad){
      setFirstLoad(true);
      load();
    }
  }, [isAuthenticated, firstLoad, load])

  const updateLocal = (event: Event) => {
    const exists = events.find(e => e.id === event.id);
    if(exists === undefined){
      setEvents([...events, event])
    } else {
      setEvents(events.map(e => e.id === event.id ? event : e))
    }
  };

  return [events, loading, loaded, load, updateLocal];
}

const drawerWidth = 240;
export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  drawer: {
    width: drawerWidth,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerContainer: {
    overflow: 'auto',
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  menuIcon: {
    transition: theme.transitions.create(["transform"], {
      duration: theme.transitions.duration.short
    })
  },
  drawerOpen: {
    transform: "rotate(90deg)"
  },
  drawerClosed: {
    transform: "rotate(0)"
  },
  eventsList: {
    display: 'flex',
    flexDirection: "column",
    width: '100%'
  },
  mainContent: {
    display: 'flex',
    left: 0,
    flexDirection: "column",
    marginLeft: -drawerWidth,
    width: '100%',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  list: {
    display: 'flex',
    flexDirection: "row",
    width: "100%",
    textAlign: "center"
  },
  row: {
    display: "flex",
    flexDirection: "row"
  },
  addButton: {
    fontSize: "0.5em",
  },
}));