import { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
});

function Row(props: { headers: string[], row: any, colors?: {[key:string]:{[key:string]:string}}}) {
  const { row, headers, colors } = props;
  const [open, setOpen] = useState(false);
  const classes = useRowStyles();

  return (
    <>
      <TableRow className={classes.root}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        {headers.map((header:string, index:number) => {
          let props: {[key:string]: string} = {align:"right"};
          if (index === 0) props = {component:"th", scope:"row"}
          if(colors!==undefined && header in colors && row.values[header] in colors[header]){
            props.color = colors[header][row.values[header]]
          }
          return (
            <TableCell key={index} {...props}>
              {row.values[header]}
            </TableCell>
          )
        })}
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Table size="small" aria-label="events">
                <TableHead>
                  <TableRow>
                    {!!row.sub && row.sub.headers.map((subHeader:string, index:number) => {
                      let props: {[key:string]: string} = {align:"right"};
                      if (index <= 1) props = {}
                      return (
                        <TableCell key={index} {...props}>
                          {subHeader}
                        </TableCell>
                      )
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    {!!row.sub && row.sub.headers.map((subHeader:string, index:number) => {
                      let props: {[key:string]: string} = {align:"right"};
                      if (index === 0) props = {component:"th", scope:"row"}
                      if (index === 1) props = {}
                      return (
                        <TableCell key={index} {...props}>
                          {row.sub.values[subHeader]}
                        </TableCell>
                      )
                    })}
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

export const CollapsibleTable = ({rows, headers, colors}: {rows: any[], headers: string[], colors?: {[key:string]:{[key:string]:string}}}) => {
  const headerCells = headers.map((header, index) => (
    <TableCell {...(index > 0 ? {align:"right"} : {})}>
      {!header.startsWith("__")?header:""}
    </TableCell>
  ));
  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell />
            {
              headerCells
            }
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.length > 0 && rows.map((row, index) => (
            <Row key={index} headers={headers} row={row} colors={colors}/>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}